<template>
  <div
    :class="'credit-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/member_bg.jpg')" />
      <div class="credit-info">
        <div class="credit">
          {{ PAGELANG.可提现收益 }}
          <span class="num price">{{ agentInfo.Withdrawal }}</span>
        </div>
        <div class="other">
          <div class="item">
            {{ PAGELANG.累计收益 }}
            <span class="num price">{{ agentInfo.Profit }}</span>
          </div>
          <div class="item">
            {{ PAGELANG.冻结收益 }}
            <span class="num price">{{ agentInfo.ToBeRecorded }}</span>
          </div>
        </div>
        <a class="btn-withdraw" @click="goWithdraw">{{ PAGELANG.提现 }}</a>
      </div>
    </div>

    <ul class="credit-menu">
      <li>
        <a href="#/income/account">
          <span class="title">
            <i class="iconfont icon-qianbao-03" style="color: #f5a624" />
            {{ PAGELANG.提现账户 }}
          </span>
          <span>{{ agentInfo.AccountCount }}</span>
        </a>
      </li>
      <li>
        <a href="#/income/withdrawlogs">
          <span class="title">
            <i class="iconfont icon-qianbao-02" style="color: #f5a624" />
            {{ PAGELANG.提现明细 }}
          </span>
          <span class="price">{{ agentInfo.Cashout }}</span>
        </a>
      </li>
      <li>
        <a href="#/income/toberecorded">
          <span class="title">
            <i class="iconfont icon-qianbao-01" style="color: #3cadff" />
            {{ PAGELANG.待结算 }}
          </span>
          <span class="price">{{ agentInfo.ToBeRecorded }}</span>
        </a>
      </li>
      <li>
        <a href="#/income/logs">
          <span class="title">
            <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
            {{ PAGELANG.收益明细 }}
          </span>
          <span class="price">{{ agentInfo.Profit }}</span>
        </a>
      </li>
    </ul>
  </div>

  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <MyMenu :agentInfo="agentInfo" pagename="income" />
    <div class="main">
      <div class="credit-info">
        <div class="item">
          <span class="num price">{{ agentInfo.Withdrawal }}</span>
          {{ PAGELANG.可提现收益 }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.Profit }}</span>
          {{ PAGELANG.累计收益 }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.ToBeRecorded }}</span>
          {{ PAGELANG.冻结收益 }}
        </div>
      </div>
      <ul class="credit-menu">
        <li>
          <a href="#/income/account">
            <span class="title">
              <i class="iconfont icon-qianbao-03" />
              {{ PAGELANG.提现账户 }}
            </span>
            <span>{{ agentInfo.AccountCount }}</span>
          </a>
        </li>
        <li>
          <a @click="goWithdraw" style="cursor: pointer">
            <span class="title">
              <i class="iconfont icon-tixian1" />
              {{ PAGELANG.提现 }}
            </span>
          </a>
        </li>
        <li>
          <a href="#/income/withdrawlogs">
            <span class="title">
              <i class="iconfont icon-qianbao-02" />
              {{ PAGELANG.提现明细 }}
            </span>
            <span class="price">{{ agentInfo.Cashout }}</span>
          </a>
        </li>
        <li>
          <a href="#/income/toberecorded">
            <span class="title">
              <i class="iconfont icon-qianbao-01" />
              {{ PAGELANG.待结算 }}
            </span>
            <span class="price">{{ agentInfo.ToBeRecorded }}</span>
          </a>
        </li>
        <li>
          <a href="#/income/logs">
            <span class="title">
              <i class="iconfont icon-qianbao-04" />
              {{ PAGELANG.收益明细 }}
            </span>
            <span class="price">{{ agentInfo.Profit }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  showToast,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      agentInfo: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.income;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.income, {
          withdraw: 1,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          console.log(code, msg);
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.Income.getMemberInfo.error", error);
          this.getMemberInfo();
        });
    },
    goWithdraw() {
      if (this.agentInfo.AccountList.length <= 0) {
        showConfirmDialog({
          title: this.SYSLANG.dialogtitle,
          message: this.PAGELANG.您还没有可用的提现账户是否马上添加,
          confirmButtonText: this.PAGELANG.去添加,
          cancelButtonText: this.SYSLANG.quxiao,
        })
          .then(() => {
            this.$router.push({
              path: "/income/account",
            });
          })
          .catch(() => {});
      } else if (this.agentInfo.Withdrawal * 1 <= 0) {
        // 没有可提现收益
        showToast({
          message: this.PAGELANG.暂时没有可提现收益,
        });
      } else {
        this.$router.push({
          path: "/income/withdraw",
        });
      }
    },
  },
};
</script>


<style src="../assets/css/credit.css" scoped></style>
<style scoped>
.credit-page .header .credit-info .credit {
  margin-bottom: 0;
}

.credit-page .btn-withdraw {
  display: inline-block;
  line-height: 32px;
  font-size: 14px;
  padding: 0 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
}
</style>